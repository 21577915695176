//Some environment settings
import {getMeasurementId} from "Helpers";

const MORPHEUS = window.MORPHEUS;
const {enviroment: environment} = MORPHEUS;

//Placing it here for now
const BRAND_CONFIG = MORPHEUS.core.brandConfig;
const BRAND_FEATURES = BRAND_CONFIG.features;

export const GEO_IP_COUNTRY_CODE = environment.geoIp ?? "";
export const IS_MOBILE = environment.isMobile;
export const IS_DEV = environment.reactDev;
export const IS_STAGING = environment.isStaging;
export const IS_PROD = !IS_STAGING;
export const IS_HOT_RELOAD_ENABLED = IS_DEV && window.hot ? window.hot : false;
export const HOT_RELOAD_PATH = "https://localhost:9001";

export const IS_LOGGED_IN = environment.isLoggedIn;
export const CAN_LOGIN = MORPHEUS?.core?.extended?.lf ?? true;
export const CAN_REGISTER = MORPHEUS?.core?.extended?.sf ?? true;
export const STORED_USERNAME = MORPHEUS?.core?.username ?? '';
export const PLAYER_ID = MORPHEUS?.core?.playerId ?? false;

const BINGO_BRANDS = ["bb", "db", "gb", "rb", "bd"];
export const MULTI_TIME_BINGO_ROOMS = BRAND_FEATURES?.bingoRooms?.multiTimeRooms ?? false;

//  Risk Scores
export const RISK_CATEGORY_TRIGGERS = ["medium"];
export const RISK_RATING_TRIGGERS = [5, 6, 9];
export const RISK_CATEGORY = MORPHEUS?.account?.riskCategory ?? "";
export const RISK_RATING = MORPHEUS?.account?.riskRating ?? 0;
export const RISK_STATUS_ON_MODAL_TRIGGERED_CLAIM_NAME = "RiskStatusOnModalTriggered";

export let IS_GOOGLE_BOT = false;
if (window?.navigator?.userAgent) {
    const knownBots = ["googlebot, lighthouse"];
    const ua = window.navigator.userAgent;

    knownBots.forEach(bot => {
        if (ua.toLowerCase().indexOf(bot) >= 0) {
            IS_GOOGLE_BOT = true;
        }
    })
}

if (IS_DEV && IS_LOGGED_IN) {
    MORPHEUS.bootEnviroment = {
        ftdPlayer: false,
        justLoggedIn: true,
        // promoCode: "Test_Sig_Terms",
        showWelcomeLightbox: true
    }
}

// WELCOME LIGHTBOX
export const FTD_PLAYER = MORPHEUS?.bootEnviroment?.ftdPlayer ?? false;
export const JUST_LOGGED_IN = MORPHEUS?.bootEnviroment?.justLoggedIn ?? false;
export const WELCOME_LIGHTBOX_PROMOCODE = MORPHEUS?.bootEnviroment?.promoCode ?? false;
export const SHOW_WELCOME_LIGHTBOX = MORPHEUS?.bootEnviroment?.showWelcomeLightbox ?? false;
export const IS_LOGIN_LIGHTBOX_ENABLED = IS_LOGGED_IN && true && WELCOME_LIGHTBOX_PROMOCODE;

// Returning Player Lightbox Settings
export const IS_RETURNING_PLAYER_LIGHTBOX_ENABLED = true;
export const RETURNING_PLAYER_LIGHTBOX_CLAIM_NAME = "ReturningPlayerLightboxLastShownDate";

export const IS_RESPONSIBLE_GAMING_APP_ENABLED = true;
export const SERVE_WEBP_IMAGES = true;

export const APP_VERSION = BRAND_CONFIG.jsVersion ?? 'N/A';
export const BRAND_NAME = BRAND_CONFIG.brandName;
export const BRAND_THEME = BRAND_CONFIG.brandTheme;
export const BRAND_SUPPORT_EMAIL = BRAND_CONFIG.supportEmail;
export const CDN_PATH = BRAND_CONFIG.cdnUrl;
export const CDN_BRAND_PATH = BRAND_CONFIG.cdnBrandPath;

//BANKING SETTINGS
export const IS_QUICK_BANKING_ENABLED = true;
export const IS_SLIM_BANKING_ENABLED = true;
export const DEBIT_CARD_DEPOSIT_MESSAGE = "Only debit card deposits are eligible for bonuses";
export const SHOW_DEBIT_CARD_DEPOSIT_MESSAGE = true;
export const USE_NEW_BANKING_EVENT_LISTENER = true;

export const IS_APPLE_PAY_ENABLED = BRAND_CONFIG?.features?.applePay?.enabled ?? false;

export const IMAGES_PATH = IS_DEV ? (IS_HOT_RELOAD_ENABLED ? `${HOT_RELOAD_PATH}/images/${CDN_BRAND_PATH}` : `/css-js/images/${CDN_BRAND_PATH}`) : `${CDN_PATH}/images/${CDN_BRAND_PATH}`;
export const SHARED_IMAGES_PATH = IS_DEV ? (IS_HOT_RELOAD_ENABLED ? `${HOT_RELOAD_PATH}/images/shared` : "/css-js/images/shared") : `${CDN_PATH}/images/shared`;
export const USE_NEW_CDN = !!BRAND_FEATURES?.imageCdn?.useNewCdn;
export const OLD_SHARED_CDN_PATH = 'https://images.broadwaygaming.com';
export const NEW_SHARED_CDN_PATH = 'https://images2.broadwaygaming.com/content';
export const SHARED_CDN_PATH = USE_NEW_CDN ? NEW_SHARED_CDN_PATH : OLD_SHARED_CDN_PATH;
export const SHARED_CDN_BRAND_ID = BRAND_CONFIG.sharedImagesId;

//IMAGE PATH CONSTANTS
export const BINGO_ROOMS_IMAGES = `${SHARED_CDN_PATH}/BingoRooms/${SHARED_CDN_BRAND_ID}`;
export const BINGO_ROOMS_SHARED_IMAGES = `${SHARED_CDN_PATH}/BingoRooms/Shared`;
export const SPIN_THE_WHEEL_IMAGES_PATH = `${SHARED_CDN_PATH}/Promotions/Shared/SpinTheWheel`;

export const CASINO_ICON_PATH = `${SHARED_CDN_PATH}/CasinoIcons`;
export const CASINO_ICON_SIZE_DIRECTORIES = ['horizontal', 'vertical', 'large'];
export const ALLOW_RETINA_CASINO_ICONS = false;

//REGISTRATION
export const IS_OCCUPATION_FIELD_ENABLED = BRAND_FEATURES?.registration?.occupation?.enabled ?? true;
export const IS_NATIONALITY_FIELD_ENABLED = true;

// FEATURES
export const REWARDS_ENABLED = BRAND_FEATURES?.rewards?.enabled ?? false;
export const REWARDS_SCHEME_NAME = BRAND_FEATURES?.rewards?.schemeName ?? "Rewards";
export const BINGO_ENABLED = BINGO_BRANDS.includes(BRAND_THEME);
export const ANIMATED_BINGO_CARD_ENABLED = !IS_MOBILE;
export const ANIMATED_GAME_ICONS_ENABLED = !IS_MOBILE;
export const ACTIVATED_GAME_ICONS_ENABLED = true;
export const DISPLAY_NET_POSITION_AND_SESSION = false;
export const SG_WEEK_ENABLED = BRAND_FEATURES?.sgWeekEnabled ?? false;
export const SG_WEEK_DATES_STRING = "18 - 24 November 2024";
export const SHOW_PJP_ON_BINGO_CARDS = true;
export const PICK_YOUR_BONUS_ENABLED = IS_LOGGED_IN;
export const SPIN_THE_WHEEL_ENABLED = (IS_LOGGED_IN && BRAND_FEATURES?.spinTheWheel?.enabled) ?? false;
export const IS_CAMPAIGN_MANAGER_PUSH_NOTIFICATIONS_ENABLED = true;
export const IS_FREE_SPINS_ENABLED = BRAND_FEATURES?.freeSpins?.enabled ?? false;
export const MGS_FREE_SPINS_CONFIG = BRAND_FEATURES?.freeSpins?.mgs ?? [];

export const DF_ROOMS_ROOM_SETTINGS = BRAND_FEATURES?.bingoRooms?.dfRoomSettings ?? [];
export const IS_DF_BINGO_ENABLED = (BRAND_FEATURES?.bingoRooms?.isDFRoomsEnabled && DF_ROOMS_ROOM_SETTINGS.length > 0) ?? false;
export const USE_DRAGONFISH_BINGO_FEED = IS_DF_BINGO_ENABLED;

export const MAX_GAMES_IN_RECENTLY_PLAYED = 9;
// export const MAX_GAMES_IN_FAVOURITES = 16;
export const USER_PREFERRED_GAMES_TAB_ENABLED = true && IS_LOGGED_IN;
export const IS_FAVOURITE_GAMES_ENABLED = false && IS_LOGGED_IN;

const UNIX_NOW = Math.floor(Date.now() / 1000);

const START_SPECIAL_EVENT = Math.floor(new Date('12/09/2024 00:00') / 1000);
const END_SPECIAL_EVENT = Math.floor(new Date('12/20/2024 23:59') / 1000);

export const SPECIAL_EVENT_PROMO_ENABLED = IS_LOGGED_IN && !["cf"].includes(BRAND_THEME) && (IS_STAGING || (UNIX_NOW > START_SPECIAL_EVENT && UNIX_NOW < END_SPECIAL_EVENT));
export const SPECIAL_EVENT_PROMO_NAME = "Xmas Deals";
export const SPECIAL_EVENT_PROMO_MASTERGROUP = "XMAS24_MASTERGROUP";
export const SPECIAL_EVENT_PROMO_PREFIX = "XMAS24_DAY";
export const SPECIAL_EVENT_PROMO_URL = "/12-deals-of-xmas";
export const SPECIAL_EVENT_PROMO_CLASS = "xmas-deals";

const START_OUTAGE = Math.floor(new Date('11/29/2023 05:00') / 1000);
const END_OUTAGE = Math.floor(new Date('11/29/2023 07:00') / 1000);
const IS_OUTAGE = (UNIX_NOW > START_OUTAGE && UNIX_NOW < END_OUTAGE);

export const PROMOS_ENABLED = true;
export const DISABLED_GAME_PROVIDERS = (IS_LOGGED_IN && IS_OUTAGE) ? ["PragmaticBingo"] : [];
export const BINGO_MAINTENANCE = DISABLED_GAME_PROVIDERS.includes("PragmaticBingo");

export const SERVICE_ANNOUNCEMENT_ENABLED = IS_OUTAGE;
export const SERVICE_ANNOUNCEMENT_MESSAGE = "Maintenance is currently being carried out on some games until 7am. The Bingo Rooms are closed for now, please enjoy slot games in the meantime or pop back after 7am, when the rooms will be available again.";

export const BINGO_SERVICE_ANNOUNCEMENT_ENABLED = IS_OUTAGE && BINGO_MAINTENANCE;
export const BINGO_SERVICE_ANNOUNCEMENT_MESSAGE = "Maintenance is currently being carried out on some games until 7am. The Bingo Rooms are closed for now, please enjoy slot games in the meantime or pop back after 7am, when the rooms will be available again.";

export const LOAD_ACCOUNT_VERIFICATION_PAGES_FROM_FORGE = true;

// BINGO
export const LOAD_MINI_GAMES_FROM_MORPHEUS = true;

// CUSTOM SKINS
const SKINS_UNIX_NOW = Math.floor(Date.now() / 1000);
const SKINS_START_DATE = Math.floor(new Date('10/28/2023 00:00') / 1000);
const SKINS_END_DATE = Math.floor(new Date('10/31/2023 23:59') / 1000);
const SKINS_IS_ENABLED = (SKINS_UNIX_NOW > SKINS_START_DATE && SKINS_UNIX_NOW < SKINS_END_DATE);
export const CUSTOM_SITE_SKIN_ENABLED = false;
export const CUSTOM_SITE_SKIN_NAME = "xmas-2022";

// LANDING PAGE SETTINGS
const LANDING_PAGE_SETTINGS = BRAND_CONFIG.landingPages;
export const LANDING_PAGES_ENABLED = (LANDING_PAGE_SETTINGS?.landingPagesEnabled ?? false) && CAN_REGISTER;
export const NEW_PLAYER_HOMEPAGE_ENABLED = (LANDING_PAGE_SETTINGS?.newPlayerHomepageEnabled ?? false) && CAN_REGISTER;
export const DEFAULT_LANDING_PAGE_ROUTE = LANDING_PAGE_SETTINGS?.defaultLandingPage ?? "/";
// export const REDIRECT_ALL_LANDING_PAGES_TO_DEFAULT = LANDING_PAGES_ENABLED ? LANDING_PAGE_SETTINGS?.redirectAllToDefault ?? false : false;
// export const REDIRECT_MISSING_LANDING_PAGES_TO_HOMEPAGE = LANDING_PAGE_SETTINGS?.redirectNonExistentLPsToHomepage ?? false;

// REGISTRATION
export const IS_WELCOME_BONUS_CHECKED_BY_DEFAULT = true;

// MAINTENANCE MODE
export const IS_BRAND_IN_MAINTENANCE_MODE = BRAND_CONFIG?.maintenanceMode?.brand ?? false;

// EXTERNAL SERVICES
const BRAND_EXTERNAL_SERVICES = BRAND_CONFIG.externalServices;
export const ZENDESK_CHAT_ENABLED = BRAND_EXTERNAL_SERVICES.zendesk.chat.enabled ?? false;
export const ZENDESK_SCRIPT_URL = BRAND_EXTERNAL_SERVICES.zendesk.chat.externalCodeURL;
export const ZENDESK_API_VERSION = BRAND_EXTERNAL_SERVICES.zendesk.chat.version ?? "v1";
export const HELP_CENTER_ENABLED = BRAND_EXTERNAL_SERVICES.zendesk.helpCenter.enabled ?? false;
export const HELP_CENTER_URL = BRAND_EXTERNAL_SERVICES.zendesk.helpCenter.helpCenterURL;
export const TRUSTPILOT_ENABLED = BRAND_EXTERNAL_SERVICES.trustPilot.enabled ?? false;
export const TRUSTPILOT_SETTINGS = {
    templateId: BRAND_EXTERNAL_SERVICES.trustPilot.templateId ?? false,
    businessUnitId: BRAND_EXTERNAL_SERVICES.trustPilot.businessUnitId ?? false,
    theme: BRAND_EXTERNAL_SERVICES.trustPilot.theme ?? false
};

export const IS_SNAPCHAT_TRACKING_ENABLED = BRAND_EXTERNAL_SERVICES?.tracking?.snapchat?.enabled ?? false;
export const SNAPCHAT_TRACKING_PIXEL_ID = BRAND_EXTERNAL_SERVICES?.tracking?.snapchat?.pixelId ?? null;

export const IS_MICROSOFT_TRACKING_ENABLED = BRAND_EXTERNAL_SERVICES?.tracking?.microsoft?.enabled ?? false;
export const MICROSOFT_TRACKING_PIXEL_ID = BRAND_EXTERNAL_SERVICES?.tracking?.microsoft?.pixelId ?? null;

// export const GA_ENABLED = false;
// export const GA_ID = getGASiteId();
export const GTM_ENABLED = BRAND_EXTERNAL_SERVICES.google.gtm.enabled ?? false;
// export const GTM_ID = GTM_ENABLED ? BRAND_EXTERNAL_SERVICES.google.gtm.id : false;
export const GA4_ENABLED = true;
export const GA4_ID = getMeasurementId();
// export const SEND_PLAYER_ID_AS_CUSTOM_DIMENSION = true;
export const SEND_DATA_TO_S2S_FB_PIXEL = [...BINGO_BRANDS, "cd"].includes(BRAND_THEME);
export const SEND_CONSENT_VALUE_IN_REGISTRATION_PAYLOAD = BRAND_EXTERNAL_SERVICES?.google?.sendConsentInRegistration ?? false;

// LICENSE DETAILS
const BRAND_LICENSE_DETAILS = BRAND_CONFIG.licenseDetails;
export const UKGC_LICENSE_NUMBER = BRAND_LICENSE_DETAILS.ukgcLicenseNumber;
export const UKGC_LICENSE_URL = BRAND_LICENSE_DETAILS.ukgcLicenseURL;

// REGULATED
const REGULATED = BRAND_CONFIG.regulated;
export const BONUS_MONEY_RULES_STRAPLINE = REGULATED.bonusMoneyRulesStrapline;
export const BONUS_MONEY_RULES_STRAPLINE_NEW_PLAYER = REGULATED.bonusMoneyRulesStraplineNewPlayer;
export const BONUS_MONEY_RULES_STRAPLINE_LANDING_PAGE = REGULATED.bonusMoneyRulesStraplineLandingPage;
export const FB_TERMS_ENABLED = !["cf"].includes(BRAND_THEME);

// Not allowed view any content, show full block message on all pages.
const GEO_BLOCKED_COUNTRY_LIST = ["CA"];
export const IS_CLIENT_FROM_GEO_BLOCKED_COUNTRY = GEO_BLOCKED_COUNTRY_LIST.includes(GEO_IP_COUNTRY_CODE.toUpperCase());
export const RESTRICTED_COUNTRY_ROUTE = "/restricted-country";

// User stuff
export const COOKIE_BANNER_ENABLED = !BRAND_EXTERNAL_SERVICES?.onetrust?.enabled ?? true;
export const HAS_ACCEPTED_COOKIES = !MORPHEUS.core.cookieBanner || IS_LOGGED_IN;

// Check for messages under the category 'Verification' on login?
export const VERIFICATION_MESSAGE_MODAL_ENABLED = JUST_LOGGED_IN && (BRAND_FEATURES?.checkforVerificationMessagesOnLogin ?? false);
export const VERIFICATION_MESSAGE_MODAL_CATEGORY = IS_STAGING ? "Important" : "Verification";

//Dev Stuff
export const IS_THEME_SWITCHER_ENABLED = IS_DEV && true;

function canUseWebP() {
    try {
        var elem = document.createElement('canvas');
        if (!!(elem.getContext && elem.getContext('2d'))) {
            // was able or not to get WebP representation
            return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
        }
    } catch (e) {
        return false;
    }

    // very old browser like IE 8, canvas not supported
    return false;
}

export const SUPPORTS_WEBP = canUseWebP();
